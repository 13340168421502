<!--
 * @Author: your name
 * @Date: 2022-04-01 14:18:16
 * @LastEditTime: 2022-04-21 14:29:48
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\components\vanttabbar\index.vue
-->
<template>
  <div>
  <router-view />
  <van-tabbar :safe-area-inset-bottom="true" route @change="change" v-if="(isApp!=1)">
    <van-tabbar-item :to="item.path" v-for="(item,index) in itemlist" :key="index">{{item.title}}
      <template #icon="prpos">
      <img :src="prpos.active ? item.icon.active : item.icon.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
  </div>
</template>
<script>
import mixin from "@/mixin/mixin.js"
// import isPhone from "@/untils/isPhone.js";
export default {
  data() {
    return{
      itemlist:[
        {
          path:"/index",
          title:"首页",
          icon:{
            active: require("@/assets/images/tabbar/activeindex.png"),
            inactive: require("@/assets/images/tabbar/index.png"),
          }
        },
        {
          path:"/selectpack",
          title:"套餐办理",
          icon:{
            active: require("@/assets/images/tabbar/activeorder.png"),
            inactive: require("@/assets/images/tabbar/order.png"),
          }
        },
        // {
        //   path:"/setwifi",
        //   title:"wifi设置",
        //   icon:{
        //     active: require("@/assets/images/tabbar/activewifi.png"),
        //     inactive: require("@/assets/images/tabbar/wifi.png"),
        //   }
        // },
        {
          path:"/user",
          title:"我的",
          icon:{
            active: require("@/assets/images/tabbar/activeuser.png"),
            inactive: require("@/assets/images/tabbar/user.png"),
          }
        }
      ],
      isApp:0,//判断浏览器携带的参数，如果是1表示在app和微信浏览器里打开，如果是0表示浏览器
    }  
  },
  mixins:[mixin],
  mounted() {
    // console.log(isPhone());
    var url = window.location.href;
        if (url.indexOf('?') > -1) {
            var cs = url.split('?')[1];
            var cs_arr = cs.split('&');
            var cs = {};
            for (var i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
                cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
            }
            if(cs.app == undefined){
                this.isApp = 0
            }else{
                this.isApp = cs.app 
            }
        } else {
            this.isApp = 0
        }
  },
  methods:{
    change(active){
      console.log(active);
     if(active ==1){
       let data ={
         iccid:sessionStorage.getItem("iccid"),
         phone:sessionStorage.getItem("phone"),
         path:"/selectpack"
       }
       this.isopenid(data)
     }
     if(active == 2){
      console.log('走了');
      let data ={
         iccid:sessionStorage.getItem("iccid"),
         phone:sessionStorage.getItem("phone"),
         path:"/user"
       }
       this.isopenid(data)
     }

    }
  }
}
</script>
<style lang="less" scoped>
.van-tabbar {
 z-index: 3;
 padding-bottom: 15px;
}
</style>